// @ts-ignore
import getIcon from '../assets/get-icon';
import styles from './index.module.less';

export default ({ line2, toothInfo, type, gdt }: any) => {
  const { appliance, traction } = toothInfo || {};
  const { stepStart, stepEnd, type: applianceType } = appliance || {};
  const className = line2 ? styles['step-bottom'] : styles['step-top'];
  const usefultraction = traction?.filter((f: any) => f.stepStart || f.stepEnd);
  const showStep = stepStart || stepEnd || usefultraction?.length > 0;
  const { jy, qd, ylj, yhdb, ylk, qyk, qyg, cbg, hd }: any = getIcon(type, gdt);
  const renderApplianceIcon = (type: any) => {
    return (
      <>
        {type === '1' && <img src={ylj} />}
        {type === '2' && <img src={hd} />}
        {type === '3' && <img src={jy} />}
        {type === '4' && <img src={qd} />}
        {type === '5' && <img src={yhdb} />}
        {type === '6' && <img src={qyk} />}
        {type === '7' && <img src={cbg} />}
      </>
    );
  };
  const renderTractionIcon = (type: any) => {
    return (
      <>
        {type === '1' && <img src={qyg} />}
        {type === '2' && <img src={qyk} />}
        {type === '3' && <img src={ylk} />}
        {type === '5' && <img src={cbg} />}
      </>
    );
  };
  return showStep ? (
    <div className={`${styles['box-step']} ${className}`}>
      {(stepStart || stepEnd) && (
        <div className={styles['box-step-text']}>
          {renderApplianceIcon(applianceType)}
          <span>
            {stepStart}-{stepEnd}
          </span>
        </div>
      )}
      {usefultraction?.map((v: any, i: number) => {
        return (
          <div key={i} className={styles['box-step-text']}>
            {renderTractionIcon(v.type)}
            <span>
              {v.stepStart}-{v.stepEnd}
            </span>
          </div>
        );
      })}
      <div className={styles.vline}></div>
    </div>
  ) : null;
};

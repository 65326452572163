import ProTable from '@ant-design/pro-table';
import { ConfigProvider, Form, message, Modal, theme } from 'antd';
import { nanoid } from 'nanoid';
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { OpsBtns, SwitchGroup } from '../components';
import ModalForm from '../modal-form';
import { tableStyle } from '../table/index.style';
import { TableCURDFC } from '../types';
import {
  batchRemoveRequest,
  CONTEXT,
  createRequest,
  detailRequest,
  exportTableData,
  formatColumnsCURD,
  modifyRequest,
  opsBtnRequest,
  removeRequest,
  renderOpsData,
} from '../utils';

/**
 * 📦 内置组件 - 隐力科技
 * @name CURD表格页生成
 * @package @/aismile-atom
 * @author xuguanqun
 */
const TableCURD: TableCURDFC = (
  {
    actionRef,
    api,
    ops,
    formatApiData,
    formatTableData,
    formatExportData,
    onExportLoadingChange,
    toolbar,
    bordered = true,
    columns,
    modalForm,
    search,
    onSizeChange,
    sticky,
    scroll,
    // highPerformance,
    serialNumber = false,
    rowSelection = false,
    pagination,
    className,
    export: tableExport,
    rowKey = 'id',
    beforeSearch,
    stickyBottom = true,
    ...props
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref,
) => {
  const {
    page: apiPage,
    detail: apiDetail,
    create: apiCreate,
    modify: apiModify,
    remove: apiRemove,
    batchRemove: apiBatchRemove,
    export: apiExport,
  } = api || {};
  const {
    ruleId: opsRuleId,
    funcMenuSelectedId,
    onSuccess: opsOnSuccess,
    opsColumnProps = {},
  } = ops || {};
  const {
    title: toolbarTitle,
    subTitle: toolbarTitleSubTitle,
    switchList: toolbarSwitchList,
    middleArea: toolbarMiddleArea,
    actions: toolbarActions = [],
    settings: toolbarSettings = true,
  } = toolbar || {};
  const showToolBar =
    toolbarSettings &&
    (toolbarTitle ||
      toolbarTitleSubTitle ||
      toolbarSwitchList ||
      toolbarMiddleArea);
  /**
   * @name state
   */
  const tableRef: any = useRef();
  const searchRef: any = useRef();
  const [formRef] = Form.useForm();
  const [tableId] = useState(nanoid());
  const [, _settableSize] = useState<string>('middle'); // 表格尺寸
  const [collapse, setcollapse] = useState(true);
  const [_tableData, _settableData] = useState<Record<string, any>>();
  const [_selectedRowKeys, _setselectedRowKeys] = useState<any[]>([]);
  const [_selectedRows, _setselectedRows] = useState<any[]>([]);
  const [exportLoading, setexportLoading] = useState(false); // 导出loading
  /** @name ModalForm */
  const [modalFormType, setmodalFormType] = useState<
    'CREATE' | 'MODIFY' | 'DETAIL'
  >('DETAIL'); // 1新增 2修改 3详情
  const [modalFormVisible, setmodalFormVisible] = useState<boolean>();
  const [_record, _setrecord] = useState(); // 表格行数据
  const [modalFormRecord, setmodalFormRecord] = useState(null); // 表单填充
  const [scrollY, setscrollY] = useState<number>();
  /**
   * @name props
   */
  const {
    detailTitle = '详情',
    createTitle = '新增',
    modifyTitle = '修改',
    removeTitle = '确认删除？',
    batchRemoveTitle = '确认删除？',
    exportTitle = '导出',
    formItemList: _formItemList = [],
    beforeSetFields,
    afterCreate,
    afterModify,
    afterRemove,
    afterExport,
    ...otherModalFormProps
  } = modalForm || {};
  const formItemList =
    typeof _formItemList === 'function'
      ? _formItemList(modalFormType)
      : _formItemList;
  let _rowSelection = {
    selectedRowKeys: _selectedRowKeys,
    onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
      _setselectedRowKeys(selectedRowKeys);
      _setselectedRows(selectedRows);
    },
  };
  // merge
  if (typeof rowSelection === 'object') {
    _rowSelection = { ..._rowSelection, ...rowSelection };
  }
  /**
   * @name CONTEXT
   */
  const ANTD_CONTEXT = useContext(ConfigProvider.ConfigContext);
  const prefixCls = ANTD_CONTEXT.getPrefixCls();
  const _CONTEXT: Record<string, any> = useContext(CONTEXT);
  const {
    api: { permissionBtnApi },
    req: { request, useRequest },
  } = _CONTEXT;
  // 分页器
  const {
    defaultPageSize = 10,
    showSizeChanger = true,
    showQuickJumper = true,
    ...paginationProps
  } = pagination || {};
  // 详情弹窗
  const isDetailModal = modalFormType === 'DETAIL';
  const hideFoot = isDetailModal ? { footer: false } : {};
  /**
   * @name theme
   */
  const {
    token: { colorPrimary, colorPrimaryBorderHover },
  } = theme.useToken();
  /**
   * @name 获取权限按钮
   */
  const { run: opsBtnRun, data: opsBtn = {} } = useRequest(
    (ruleId: string) => opsBtnRequest(request, permissionBtnApi, ruleId),
    {
      manual: true,
      formatResult: (res: Record<string, any>) => {
        const renderData = renderOpsData(res?.data);
        if (opsOnSuccess) {
          opsOnSuccess(renderData, res?.data);
        }
        return renderData;
      },
    },
  );
  /**
   * @name 获取详情数据
   */
  const { run: detailRun, data: detailData } = useRequest(
    (record: Record<string, any>) => detailRequest(request, apiDetail, record),
    {
      manual: true,
    },
  );
  /**
   * @name 新增
   */
  const { run: createRun } = useRequest(
    (params: Record<string, any>) => createRequest(request, apiCreate, params),
    {
      manual: true,
      onSuccess: () => {
        message.success(`${createTitle}成功`);
        setmodalFormVisible(false);
        if (tableRef?.current?.reload) {
          tableRef.current.reload();
        }
        if (afterCreate) {
          afterCreate();
        }
      },
    },
  );
  /**
   * @name 修改
   */
  const { run: modifyRun } = useRequest(
    (params: Record<string, any>, _detailData: Record<string, any>) =>
      modifyRequest(request, apiModify, params, _detailData),
    {
      manual: true,
      onSuccess: () => {
        message.success(`${modifyTitle}成功`);
        setmodalFormVisible(false);
        if (tableRef?.current?.reload) {
          tableRef.current.reload();
        }
        if (afterModify) {
          afterModify();
        }
      },
    },
  );
  /**
   * @name 删除
   */
  const { run: removeRun } = useRequest(
    (record: Record<string, any>) => removeRequest(request, apiRemove, record),
    {
      manual: true,
      onSuccess: () => {
        message.success(`删除成功`);
        resetSelect();
        if (tableRef?.current?.reload) {
          tableRef.current.reload();
        }
        if (afterRemove) {
          afterRemove();
        }
      },
    },
  );
  /**
   * @name 批量删除
   */
  const { run: batchRemoveRun } = useRequest(
    (selectedRowKeys: string[], selectedRows: Record<string, any>[]) =>
      batchRemoveRequest(
        request,
        apiBatchRemove,
        selectedRowKeys,
        selectedRows,
      ),
    {
      manual: true,
      onSuccess: () => {
        message.success(`删除成功`);
        if (tableRef?.current?.reload) {
          tableRef.current.reload();
        }
        resetSelect();
        if (afterRemove) {
          afterRemove();
        }
      },
    },
  );
  /**
   * @name 导出
   */
  const { run: exportRun } = useRequest(
    (url: string, params: Record<string, any>) =>
      request(url, { method: 'POST', data: params }),
    {
      manual: true,
      formatResult: (result: Record<string, any>) => {
        let resultData: Record<string, any> = {};
        if (formatApiData) {
          resultData = formatApiData(result);
        } else {
          resultData = result?.data;
        }
        return resultData;
      },
      onSuccess: (result: Record<string, any>) => {
        let list = [];
        if (formatExportData) {
          list = formatExportData(result?.list);
        } else {
          list = result?.list;
        }
        exportTableData({
          data: list,
          exportName:
            tableExport?.fileName || toolbar?.title?.toString() || '导出',
          exportFileFormat: tableExport?.fileFormat || 'xlsx',
          columns,
        })
          .then(() => {
            message.success(`${exportTitle}成功`);
            if (afterExport) {
              afterExport();
            }
          })
          .catch((err) => message.error(err))
          .finally(() => setexportLoading(false));
      },
      onError: () => {
        setexportLoading(false);
      },
    },
  );
  /**
   * @name 自定义请求表格函数
   */
  const getTableData: any = async (
    requestParams: Record<string, any> = {},
    sort: any,
  ) => {
    const { _apiPage, current, pageSize, ...searchFormParams } = requestParams;
    let PAGE: string | undefined = '';
    let PAGE_PARAMS: Record<string, any> | undefined = {};
    if (typeof _apiPage === 'string') {
      PAGE = _apiPage;
    } else if (Array.isArray(_apiPage)) {
      PAGE = _apiPage?.[0];
      PAGE_PARAMS = _apiPage?.[1] || {};
    } else {
      const _PAGE: any[] = _apiPage();
      PAGE = _PAGE[0];
      PAGE_PARAMS = _PAGE[1];
    }
    let data: Record<string, any> = {
      pageNum: current,
      pageSize,
      ...PAGE_PARAMS,
      ...searchFormParams,
    };
    // 排序字段
    if (sort) {
      for (const key in sort) {
        if (sort.hasOwnProperty(key)) {
          const { sorterDict } = columnDict[key] || {};
          const sortFields = sorterDict[sort[key]];
          data = { ...data, ...sortFields };
        }
      }
    }
    if (beforeSearch) {
      data = beforeSearch(data);
    }
    const result: any = await request(PAGE, {
      method: 'POST',
      data,
    });
    let resultData: Record<string, any> = {};
    if (formatApiData) {
      resultData = formatApiData(result);
    } else {
      resultData = result?.data;
    }
    const tableCache = {
      ...resultData,
      PAGE,
      PAGE_PARAMS,
      searchFormParams,
    };
    _settableData(tableCache);
    const { list, total } = resultData;
    return { data: list, total };
  };
  /**
   * @name functions
   */
  const insertForm = (data: Record<string, any>) => {
    let _data: any = { ...data };
    if (beforeSetFields) {
      _data = beforeSetFields(data, modalFormType);
    }
    setmodalFormRecord(_data);
  };
  const modalOnOk = (params: Record<string, any>) => {
    if (modalFormType === 'CREATE') {
      // 新增
      createRun(params);
    } else if (modalFormType === 'MODIFY') {
      // 修改
      modifyRun(params, detailData || _record);
    }
    setmodalFormRecord(null);
  };
  /**
   * @name 默认方法
   */
  const create = () => {
    setmodalFormVisible(true);
    setmodalFormType('CREATE');
  };
  const detail = async (record: any) => {
    _setrecord(record);
    setmodalFormVisible(true);
    setmodalFormType('DETAIL');
    const insertData = await detailRun(record);
    insertForm(insertData || record);
  };
  const modify = async (record: any) => {
    setmodalFormVisible(true);
    setmodalFormType('MODIFY');
    let insertData: any = {};
    if (apiDetail) {
      insertData = await detailRun(record);
    } else {
      // 没有配置详情接口时，使用表格行数据进行回填
      insertData = record;
    }
    insertForm(insertData);
  };
  const remove = (record: any) => {
    return Modal.confirm({
      zIndex: 500,
      title: removeTitle,
      onOk: () => {
        return removeRun(record);
      },
    });
  };
  const batchRemove = () => {
    if (!_selectedRowKeys?.length) {
      return null;
    }
    return Modal.confirm({
      zIndex: 500,
      title: batchRemoveTitle,
      onOk: () => {
        return batchRemoveRun(_selectedRowKeys, _selectedRows);
      },
    });
  };
  const _export = () => {
    const {
      PAGE,
      total,
      PAGE_PARAMS,
      searchFormParams = {},
    } = _tableData || {};
    let _url = '';
    let _params = {};
    if (!apiExport) {
      // 没有export时使用page 使用已经生成的数据
      _url = PAGE;
      _params = {
        pageNum: 1,
        pageSize: total,
        ...PAGE_PARAMS,
      };
    } else if (apiExport) {
      // 有export时使用export
      if (typeof apiExport === 'string') {
        _url = apiExport;
      } else if (Array.isArray(apiExport)) {
        _url = apiExport[0];
        _params = { pageNum: 1, pageSize: total, ...(apiExport?.[1] || {}) };
      }
    }
    let exportParams: any = { ..._params, ...searchFormParams };
    if (beforeSearch) {
      exportParams = beforeSearch(exportParams);
    }
    setexportLoading(true);
    exportRun(_url, exportParams);
  };
  const resetSelect = () => {
    _setselectedRowKeys([]);
    _setselectedRows([]);
  };
  /**
   * @name useImperativeHandle
   */
  useImperativeHandle<any, any>(actionRef, () => {
    return {
      table: {
        ...(tableRef?.current || {}),
        export: _export,
        resetSelect,
      },
      search: searchRef?.current,
      form: formRef,
    };
  });
  useEffect(() => {
    if (opsRuleId) {
      opsBtnRun(opsRuleId);
    }
  }, [opsRuleId]);
  useEffect(() => {
    if (onExportLoadingChange) {
      onExportLoadingChange(exportLoading);
    }
  }, [exportLoading]);
  const renderScroll = () => {
    let _scrollY = 0;
    if (stickyBottom) {
      const table = document.getElementById(tableId);
      if (table) {
        const tabletotop =
          table?.getBoundingClientRect?.()?.top + window.scrollY;
        const _y = window.innerHeight - tabletotop - 55 - 56;
        _scrollY = _y;
      }
    }
    setscrollY(_scrollY);
  };
  const table = document.getElementById(tableId);
  let tabletotop = 0;
  if (table) {
    tabletotop = table?.getBoundingClientRect?.()?.top + window.scrollY;
  }
  useEffect(() => {
    if (_tableData && stickyBottom) {
      renderScroll();
    }
  }, [_tableData, tabletotop, collapse]);
  useEffect(() => {
    if (_tableData && stickyBottom) {
      window.addEventListener('resize', () => {
        renderScroll();
      });
    }
  }, [_tableData]);
  /**
   * @name render
   */
  const opsProps: any = {
    prefixCls,
    tableData: _tableData,
    selectedRowKeys: _selectedRowKeys,
    selectedRows: _selectedRows,
    create,
    batchRemove,
    export: _export,
    exportLoading,
    detail,
    modify,
    remove,
  };
  const columns2comp = formatColumnsCURD(columns, serialNumber);
  const columnDict: any = {};
  columns2comp.forEach((v: any) => (columnDict[v.dataIndex || v.title] = v));
  const finalColumns: any = [
    ...columns2comp,
    opsBtn?.tableAction?.length > 0 && {
      title: '操作',
      fixed: 'right',
      search: false,
      render: (
        text: React.ReactNode,
        record: Record<string, any>,
        index: number,
        action: any,
      ) => {
        return (
          <OpsBtns
            data={
              funcMenuSelectedId
                ? opsBtn?.tableActionDict[funcMenuSelectedId]
                : opsBtn?.tableAction
            }
            ops={ops}
            position="tableAction"
            opsColProps={{ text, record, index, action }}
            {...opsProps}
          />
        );
      },
      ...opsColumnProps,
    },
  ].filter(Boolean);
  const showSearch =
    search || finalColumns?.filter((fil: any) => fil.search)?.length > 0;
  const _scrollX =
    scroll?.x ||
    finalColumns
      .filter((fil: any) => !fil?.hideInTable)
      .map((v: any) => v.width || 0)
      .reduce((a: any, b: any) => a + b, 0);

  const _scrollY = scroll?.y || scrollY;
  return (
    <>
      <ProTable
        id={tableId}
        className={[
          tableStyle({
            prefixCls,
            showToolBar,
            colorPrimary,
            colorPrimaryBorderHover,
          }),
          className,
        ]
          .filter(Boolean)
          .join(' ')}
        actionRef={tableRef}
        formRef={searchRef}
        toolbar={undefined}
        headerTitle={
          showToolBar && (
            <>
              <div className={`${prefixCls}-custom-title`}>
                <span>{toolbarTitle}</span>
                <span className={`${prefixCls}-custom-subtitle`}>
                  {toolbarTitleSubTitle}
                </span>
              </div>
              <SwitchGroup options={toolbarSwitchList} />
              {toolbarMiddleArea && (
                <div className={`${prefixCls}-middle-area`}>
                  {toolbarMiddleArea}
                </div>
              )}
            </>
          )
        }
        options={showToolBar ? { fullScreen: true, reload: true } : false}
        toolBarRender={() => [
          opsBtn?.tableHeader?.length > 0 && (
            <OpsBtns
              key=""
              data={
                funcMenuSelectedId
                  ? opsBtn?.tableHeaderDict[funcMenuSelectedId]
                  : opsBtn?.tableHeader
              }
              ops={ops}
              position="tableHeader"
              {...opsProps}
            />
          ),
          ...toolbarActions,
        ]}
        request={getTableData}
        params={{ _apiPage: apiPage }}
        postData={formatTableData}
        search={
          showSearch
            ? {
                optionRender: (searchConfig, _formProps, dom) => dom.reverse(),
                showHiddenNum: true,
                labelWidth: 'auto',
                span: {
                  xs: 24,
                  sm: 24,
                  md: 12,
                  lg: 8,
                  xl: 6,
                  xxl: 6,
                },
                collapsed: collapse,
                onCollapse: (e) => {
                  setcollapse(e);
                },
              }
            : false
        }
        bordered={bordered}
        columns={finalColumns}
        scroll={{
          x: _scrollX,
          y: _scrollY,
        }}
        onSizeChange={(size: any) => {
          if (onSizeChange) {
            onSizeChange(size);
          }
          _settableSize(size);
        }}
        revalidateOnFocus={false}
        sticky={sticky}
        rowSelection={rowSelection && _rowSelection}
        pagination={{
          defaultPageSize,
          showSizeChanger,
          showQuickJumper,
          ...paginationProps,
        }}
        tableAlertRender={false}
        tableAlertOptionRender={false}
        rowKey={rowKey}
        {...props}
      />
      <ModalForm
        form={formRef}
        data={modalFormRecord}
        title={
          {
            CREATE: createTitle,
            MODIFY: modifyTitle,
            DETAIL: detailTitle,
          }[modalFormType]
        }
        open={modalFormVisible}
        onOk={modalOnOk}
        onCancel={() => {
          setmodalFormRecord(null);
          setmodalFormVisible(false);
        }}
        formItemList={formItemList}
        readonly={isDetailModal}
        {...hideFoot}
        {...otherModalFormProps}
      />
    </>
  );
};

export default forwardRef(TableCURD);

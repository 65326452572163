import { CustomEmpty } from '@/components';
import { ICONFONT } from '@/constants';
import {
  legacyLogicalPropertiesTransformer,
  StyleProvider,
} from '@ant-design/cssinjs';
import { history, RuntimeConfig, UseRequestProvider } from '@umijs/max';
import { ConfigProvider as Antd_Es_C_P, message } from 'antd';
import { ConfigProvider as Antd_Lib_C_P } from 'antd/lib';
import enUS from 'antd/locale/en_US';
import zhCN from 'antd/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/zh-cn';

/**
 * @name 获取初始化数据
 */
export const getInitialState: RuntimeConfig['getInitialState'] = async () => {
  const passAccess = ['/compatible/clinical-operation-order']; // 跳过鉴权的页面 用于直接iframe引用
  if (
    passAccess.includes(window.location.pathname) &&
    !window.compatibleParams
  ) {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token') || '';
    window.compatibleParams = { token };
  } else if (!window?.compatibleParams?.token) {
    return history.push('/403');
  }
  return window.compatibleParams;
};
/**
 * @name 根节点
 */
export const rootContainer: RuntimeConfig['rootContainer'] = (container) => {
  const { theme: _theme, locale: _locale = 'zh-CN' } =
    window.compatibleParams || {};
  const antdLocaleDict: any = {
    'zh-CN': zhCN,
    'en-US': enUS,
  };
  const locale = antdLocaleDict[_locale];
  dayjs.locale(locale?.locale);
  const antdCP = {
    prefixCls: 'compatible',
    theme: { ..._theme },
    locale,
    renderEmpty: () => <CustomEmpty />,
  };
  return (
    <UseRequestProvider
      value={{
        manual: true,
        throwOnError: true,
      }}
    >
      <StyleProvider
        hashPriority="high"
        transformers={[legacyLogicalPropertiesTransformer]}
      >
        <Antd_Lib_C_P {...antdCP}>
          <Antd_Es_C_P {...antdCP}>{container}</Antd_Es_C_P>
        </Antd_Lib_C_P>
      </StyleProvider>
    </UseRequestProvider>
  );
};
/**
 * @name layout
 */
export const layout: RuntimeConfig['layout'] = () => {
  return {
    iconfontUrl: ICONFONT,
    pure: true,
  };
};
/**
 * @name 请求
 */
export const request: RuntimeConfig['request'] = {
  // timeout: 10000,
  errorConfig: {
    errorHandler: (error: any) => {
      const { name, message: msg } = error;
      if (name === 'AxiosError') {
        // 网络错误
        // return history.replace('/500');
        return message.error('网络错误');
      } else if (name === 'AppError') {
        // 业务层错误
        return message.error(msg);
      }
    },
  },
  requestInterceptors: [
    (url, options) => {
      const hasProtocol = url.indexOf('http') > -1;
      const newUrl = hasProtocol ? url : `/api/${url}`;
      const newOptions: any = { ...options };
      const { token } = window.compatibleParams || {};
      if (token && !newOptions?.headers?.Authorization) {
        newOptions.headers.Authorization = token;
      }
      if (!newOptions.data) {
        newOptions.data = {};
      }
      return { url: newUrl, options: newOptions };
    },
  ],
  responseInterceptors: [
    (response: Record<string, any>): any => {
      const { code, errorMsg } = response?.data || {};
      const isBlob = response?.data instanceof Blob;
      // 业务层错误捕获
      if (Number(code) !== 200 && !isBlob) {
        return Promise.reject({
          code,
          name: 'AppError',
          message: errorMsg,
          response,
        });
      }
      return response;
    },
  ],
};
/**
 * @name 请求
 */
export const qiankun = {
  bootstrap: (props: Record<string, any>) => {
    window.compatibleParams = props?.compatibleParams;
  },
};

// @ts-ignore
import { css } from '@emotion/css';
import getIcon from '../assets/get-icon';
import styles from './index.module.less';

const useStyles = ({
  margin,
  quadrant,
  widthArr,
}: {
  margin: number;
  quadrant: string;
  widthArr: number[];
}) => {
  const appliance4width =
    quadrant === 'left' ? widthArr[1] + widthArr[2] : widthArr[1] + widthArr[0];
  return {
    mid2: css`
      left: calc(50% - 15px);
    `,
    top2: css`
      top: ${(98 - 30) / 2 - margin}px;
    `,
    bottom2: css`
      bottom: ${(98 - 30) / 2 - margin}px;
    `,
    width4: css`
      width: ${appliance4width}px !important;
      height: unset !important;
    `,
    top4: css`
      top: ${(98 - appliance4width) / 2 - margin}px;
    `,
    bottom4: css`
      bottom: ${(98 - appliance4width) / 2 - margin}px;
    `,
  };
};

export default (props: any) => {
  const { toothInfo, type, gdt } = props;
  const { lost, appliance, traction } = toothInfo;
  const { qs }: any = getIcon(type, gdt);
  return (
    <>
      {lost && <img src={qs} height={15} width={15} />}
      {appliance !== '0' && <ApplianceIcon {...props} />}
      {traction?.length > 0 && <TractionIcon {...props} />}
    </>
  );
};
const ApplianceIcon = ({
  cureType,
  upper,
  side,
  index,
  toothInfo,
  widthArr,
  margin,
  type: featuresType,
  gdt,
}: any) => {
  const { jy, qd, ylj, yhdb, hd, qyk, cbg }: any = getIcon(featuresType, gdt);
  const { appliance } = toothInfo;
  const { type } = appliance || {};
  const judeAppliancePos = (
    upper: boolean | undefined,
    side: '1' | '2',
    type: string,
    quadrant: 'left' | 'right', // 象限
    antStyles: Record<string, any>,
  ) => {
    let vertical = null;
    let horizontal = null;
    let deg = null;
    const _class = [];
    switch (type) {
      // 压力脊
      case '1':
        if (upper) {
          vertical = side === '1' ? styles.top : styles.bottom;
        } else {
          vertical = side === '2' ? styles.top : styles.bottom;
        }
        horizontal = styles.mid;
        break;
      // 颌垫
      case '2':
        horizontal = antStyles.mid2;
        if ((upper && side === '1') || (!upper && side === '2')) {
          _class.push(antStyles.top2);
        } else {
          _class.push(antStyles.bottom2);
        }
        break;
      // 假牙
      case '3':
        if (upper) {
          vertical = side === '1' ? styles.top : styles.bottom;
          deg = side === '1' ? styles.deg180 : null;
        } else {
          vertical = side === '2' ? styles.top : styles.bottom;
          deg = side === '2' ? styles.deg180 : null;
        }
        horizontal = styles.mid;
        break;
      // 前导
      case '4':
        horizontal = quadrant === 'left' ? styles.left : styles.right;
        _class.push(antStyles.width4);
        if ((upper && side === '1') || (!upper && side === '2')) {
          _class.push(antStyles.top4);
        } else {
          _class.push(antStyles.bottom4);
        }
        break;
      // 咬合导板
      case '5':
        if (upper) {
          vertical = side === '1' ? styles.top : styles.bottom;
          deg = side === '2' ? styles.deg180 : null;
        } else {
          vertical = side === '2' ? styles.top : styles.bottom;
          deg = side === '1' ? styles.deg180 : null;
        }
        horizontal = styles.mid;
        break;
      // 牵引扣
      case '6':
        if (upper) {
          vertical = side === '1' ? styles.top : styles.bottom;
          deg = side === '1' ? styles.deg180 : null;
        } else {
          vertical = side === '2' ? styles.top : styles.bottom;
          deg = side === '2' ? styles.deg180 : null;
        }
        horizontal = styles.mid;
        break;
      // 长臂钩附件
      case '7':
        if (upper) {
          vertical = side === '1' ? styles.top : styles.bottom;
          deg = side === '2' ? styles.deg180 : null;
        } else {
          vertical = side === '2' ? styles.top : styles.bottom;
          deg = side === '1' ? styles.deg180 : null;
        }
        horizontal = styles.mid;
        break;
    }
    return [
      type === '2' ? styles['box-icon-hd'] : styles['box-icon'],
      ..._class,
      vertical,
      horizontal,
      deg,
    ]
      .filter(Boolean)
      .join(' ');
  };
  const judeNum = cureType === 'C' ? 4 : 7;
  const quadrant = index <= judeNum ? 'left' : 'right';
  const antStyles = useStyles({ margin, quadrant, widthArr });
  const iconclassName = judeAppliancePos(
    upper,
    side,
    type,
    quadrant,
    antStyles,
  );
  return (
    <>
      {type === '1' && <img src={ylj} className={iconclassName} />}
      {type === '2' && <img src={hd} className={iconclassName} />}
      {type === '3' && <img src={jy} className={iconclassName} />}
      {type === '4' && <img src={qd} className={iconclassName} />}
      {type === '5' && <img src={yhdb} className={iconclassName} />}
      {type === '6' && <img src={qyk} className={iconclassName} />}
      {type === '7' && <img src={cbg} className={iconclassName} />}
    </>
  );
};
const TractionIcon = ({
  cureType,
  upper,
  side,
  index,
  toothInfo,
  type: featuresType,
  gdt,
}: any) => {
  const { traction } = toothInfo;
  const judeTractionPos = (
    upper: boolean | undefined,
    side: '1' | '2',
    type: string,
    mesiodistalDirection: '1' | '2' | '3',
    direction: '1' | '2',
    quadrant: 'left' | 'right', // 象限
  ) => {
    const HL_DICT: any = {
      1: styles.right,
      2: styles.mid,
      3: styles.left,
    };
    const HR_DICT: any = {
      1: styles.left,
      2: styles.mid,
      3: styles.right,
    };
    let vertical = null;
    let horizontal =
      quadrant === 'left'
        ? HL_DICT[mesiodistalDirection]
        : HR_DICT[mesiodistalDirection];
    let deg = null;
    switch (type) {
      // 牵引钩
      case '1':
        if (upper) {
          vertical = side === '1' ? styles.top : styles.bottom;
        } else {
          vertical = side === '2' ? styles.top : styles.bottom;
        }
        deg = direction === '2' ? styles.deg90 : null;
        break;
      // 牵引扣
      case '2':
        if (upper) {
          vertical = side === '1' ? styles.top : styles.bottom;
          deg = side === '1' ? styles.deg180 : null;
        } else {
          vertical = side === '2' ? styles.top : styles.bottom;
          deg = side === '2' ? styles.deg180 : null;
        }
        horizontal = styles.mid;
        break;
      // 引力扣
      case '3':
        vertical = styles.midy;
        if (upper) {
          deg = side === '2' ? styles.deg180 : null;
        } else {
          deg = side === '1' ? styles.deg180 : null;
        }
        break;
      // 长臂钩附件
      case '5':
        if (upper) {
          vertical = side === '1' ? styles.top : styles.bottom;
          deg = side === '2' ? styles.deg180 : null;
        } else {
          vertical = side === '2' ? styles.top : styles.bottom;
          deg = side === '1' ? styles.deg180 : null;
        }
        horizontal = styles.mid;
        break;
    }
    return [styles['box-icon'], vertical, horizontal, deg]
      .filter(Boolean)
      .join(' ');
  };
  const Icon = ({ item, gdt }: any) => {
    const { type, mesiodistalDirection, direction } = item || {};
    const judeNum = cureType === 'C' ? 4 : 7;
    const quadrant = index <= judeNum ? 'left' : 'right';
    const iconclassName = judeTractionPos(
      upper,
      side,
      type,
      mesiodistalDirection,
      direction,
      quadrant,
    );
    const { ylk, qyk, qyg, cbg }: any = getIcon(featuresType, gdt);
    return (
      <>
        {type === '1' && <img src={qyg} className={iconclassName} />}
        {type === '2' && <img src={qyk} className={iconclassName} />}
        {type === '3' && <img src={ylk} className={iconclassName} />}
        {type === '5' && <img src={cbg} className={iconclassName} />}
      </>
    );
  };
  return (
    <>
      {traction?.map((item: any, tractionIndex: number) => {
        return <Icon key={tractionIndex} item={item} gdt={gdt} />;
      })}
    </>
  );
};

import jy_black from './icon-black/假牙.png';
import qd_black from './icon-black/前导.png';
import ylj_black from './icon-black/压力脊.png';
import yhdb_black from './icon-black/咬合导板.png';
import ylk_black from './icon-black/引力扣.png';
import qyk_black from './icon-black/牵引扣.png';
import qyg_black from './icon-black/牵引钩.png';
import qs_black from './icon-black/缺失.png';
import cbg_black from './icon-black/长臂钩.png';
import hd_black from './icon-black/颌垫.png';
import jy_gdt from './icon-gdt/假牙.png';
import qd_gdt from './icon-gdt/前导.png';
import ylj_gdt from './icon-gdt/压力脊.png';
import yhdb_gdt from './icon-gdt/咬合导板.png';
import ylk_gdt from './icon-gdt/引力扣.png';
import qyk_gdt from './icon-gdt/牵引扣.png';
import qyg_gdt from './icon-gdt/牵引钩.png';
import cbg_gdt from './icon-gdt/长臂钩.png';
import hd_gdt from './icon-gdt/颌垫.png';
import jy from './icon/假牙.png';
import qd from './icon/前导.png';
import ylj from './icon/压力脊.png';
import yhdb from './icon/咬合导板.png';
import ylk from './icon/引力扣.png';
import qyk from './icon/牵引扣.png';
import qyg from './icon/牵引钩.png';
import qs from './icon/缺失.png';
import cbg from './icon/长臂钩.png';
import hd from './icon/颌垫.png';
export default (type: 'clinical' | 'production', gdt: boolean) => {
  const production = {
    jy: jy_black,
    qd: qd_black,
    ylj: ylj_black,
    yhdb: yhdb_black,
    ylk: ylk_black,
    qyk: qyk_black,
    qyg: qyg_black,
    qs: qs_black,
    cbg: cbg_black,
    hd: hd_black,
  };
  const clinical_normal = { jy, qd, ylj, yhdb, ylk, qyk, qyg, qs, cbg, hd };
  const clinical_gdt = {
    jy: jy_gdt,
    qd: qd_gdt,
    ylj: ylj_gdt,
    yhdb: yhdb_gdt,
    ylk: ylk_gdt,
    qyk: qyk_gdt,
    qyg: qyg_gdt,
    qs,
    cbg: cbg_gdt,
    hd: hd_gdt,
  };
  if (type === 'clinical') {
    return gdt ? clinical_gdt : clinical_normal;
  } else if (type === 'production') {
    return production;
  }
};

import styles from './index.module.less';

const Segmentation = ({
  list = [],
  renderItem,
}: {
  list: any[];
  renderItem: (item: any) => any;
}) => {
  return (
    <div className={styles.segmentation}>
      {list.map((v) => {
        const value = renderItem(v);
        return (
          <div key={v.id} title={value?.toString()} style={{ height: 23 }}>
            <span>{value || '-'}</span>
          </div>
        );
      })}
    </div>
  );
};
export default Segmentation;

import ToothMap from '../../assets/tooth-svg/tooth-map';
import ToothMapBabyLower from '../../assets/tooth-svg/tooth-map-baby-lower';
import ToothMapBabyUpper from '../../assets/tooth-svg/tooth-map-baby-upper';

export default (cureType: string, upper: boolean) => {
  let HW: number[][] = [];
  let SVG = null;
  if (cureType !== 'C') {
    HW = [
      [12, 22, 33], // [距,高,宽]
      [14, 24, 35],
      [13, 27, 35],
      [10, 31, 29],
      [8, 34, 24],
      [5, 40, 25],
      [6, 37, 22],
      [0, 47, 27],
      [0, 47, 27],
      [6, 37, 22],
      [5, 40, 25],
      [8, 34, 24],
      [10, 31, 29],
      [13, 27, 35],
      [13, 24, 35],
      [12, 22, 33],
    ];
    SVG = ToothMap;
  } else {
    HW = upper
      ? [
          [10, 26, 38],
          [9, 32, 26],
          [5, 37, 25],
          [5, 35, 24],
          [0, 43, 27],
          [0, 43, 27],
          [5, 35, 24],
          [5, 37, 25],
          [9, 32, 26],
          [10, 30, 38],
        ]
      : [
          [5, 33, 40],
          [5, 34, 27],
          [2, 43, 25],
          [2, 40, 24],
          [0, 42, 24],
          [0, 42, 24],
          [2, 40, 24],
          [2, 43, 25],
          [5, 34, 27],
          [5, 33, 40],
        ];
    SVG = upper ? ToothMapBabyUpper : ToothMapBabyLower;
  }
  let T = [];
  if (cureType !== 'C') {
    T = upper
      ? [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28]
      : [48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38];
  } else {
    T = upper
      ? [55, 54, 53, 52, 51, 61, 62, 63, 64, 65]
      : [85, 84, 83, 82, 81, 71, 72, 73, 74, 75];
  }
  const GRID_DATA = T.map((number, index) => {
    return {
      number,
      margin: HW[index][0],
      height: HW[index][1],
      width: HW[index][2],
    };
  });
  return { GRID_DATA, SVG };
};

import { useEffect, useState } from 'react';
import gridData from './assets/tooth-svg/grid-data';
import BoxIcon from './box-icon';
import BoxStep from './box-step';
// @ts-ignore
import styles from './index.module.less';
// @ts-ignore
import getIcon from './assets/get-icon';
import styles2 from './box-step/index.module.less';

type featuresType = {
  type: 'clinical' | 'production';
  gdt: boolean;
  id: string;
  cureType: string;
  toothInfoList?: Record<string, any>[];
  bracesList: Record<string, any>[];
  tractionList: Record<string, any>[];
  style?: React.CSSProperties;
};
export default ({
  type,
  gdt,
  id,
  cureType,
  toothInfoList,
  bracesList,
  tractionList,
  style,
}: featuresType) => {
  const [toothDict, settoothDict] = useState<Record<string, any>>();
  useEffect(() => {
    const _toothDict: Record<string, any> = {};
    toothInfoList
      ?.filter(({ type }) => type === '1' || type === '4')
      ?.forEach(({ number }) => {
        if (!_toothDict?.[number]) {
          _toothDict[number] = {
            1: {},
            2: {},
          };
        }
        _toothDict[number][1].lost = true;
        _toothDict[number][2].lost = true;
      });
    let _bracesList = bracesList;
    if (type === 'clinical') {
      // 过滤假牙
      _bracesList = _bracesList?.filter(({ type }) => type !== '3');
    }
    _bracesList?.forEach(
      ({ number, side, type, stepStart, stepEnd, operatorNode }) => {
        if (!_toothDict?.[number]) {
          _toothDict[number] = {
            1: {},
            2: {},
          };
        }
        _toothDict[number][side].appliance = {
          type,
          stepStart,
          stepEnd,
          operatorNode,
        };
      },
    );
    tractionList?.forEach(
      ({
        startNumber,
        startSide,
        start,
        startMesiodistalDirection,
        startDirection,
        endNumber,
        endSide,
        end,
        endMesiodistalDirection,
        endDirection,
        stepStart,
        stepEnd,
        operatorNode,
      }) => {
        // 开始牙位
        if (startNumber && startSide) {
          if (!_toothDict?.[startNumber]) {
            _toothDict[startNumber] = {
              1: {},
              2: {},
            };
          }
          if (!_toothDict?.[startNumber]?.[startSide]?.traction) {
            _toothDict[startNumber][startSide].traction = [];
          }
          _toothDict[startNumber][startSide].traction.push({
            type: start,
            mesiodistalDirection: startMesiodistalDirection,
            direction: startDirection,
            stepStart,
            stepEnd,
            operatorNode,
          });
        }
        // 结束牙位
        if (endNumber && endSide) {
          if (!_toothDict?.[endNumber]) {
            _toothDict[endNumber] = {
              1: {},
              2: {},
            };
          }
          if (!_toothDict?.[endNumber]?.[endSide]?.traction) {
            _toothDict[endNumber][endSide].traction = [];
          }
          _toothDict[endNumber][endSide].traction.push({
            type: end,
            mesiodistalDirection: endMesiodistalDirection,
            direction: endDirection,
            stepStart,
            stepEnd,
            operatorNode,
          });
        }
      },
    );
    settoothDict(_toothDict);
  }, [bracesList, tractionList]);
  return (
    <div
      id={id}
      className={[styles.features, type === 'production' && styles.production]
        .filter(Boolean)
        .join(' ')}
      data-load="true"
      style={style}
    >
      <Label type={type} gdt={gdt} />
      <FeaturesMap
        upper
        cureType={cureType}
        toothDict={toothDict}
        type={type}
        gdt={gdt}
      />
      <div className={styles.divider}></div>
      <FeaturesMap
        cureType={cureType}
        toothDict={toothDict}
        type={type}
        gdt={gdt}
      />
    </div>
  );
};
const Label = ({ type, gdt }: any) => {
  const { jy, qd, ylj, yhdb, ylk, qyk, qyg, qs, cbg, hd }: any = getIcon(
    type,
    gdt,
  );
  const LABEL_LIST: any[] = [
    { title: '缺失牙/拔牙', icon: <img src={qs} /> },
    { title: '牵引扣', icon: <img src={qyk} /> },
    { title: '颌垫', icon: <img src={hd} /> },
    {
      title: '牵引钩',
      icon: <img src={qyg} style={{ transform: 'scaleX(-1)' }} />,
    },
    { title: '压力脊', icon: <img src={ylj} /> },
    type === 'production' && { title: '假牙', icon: <img src={jy} /> },
    {
      title: '长臂钩附件',
      icon: <img src={cbg} style={{ transform: 'rotate(-90deg)' }} />,
    },
    { title: '引力扣', icon: <img src={ylk} /> },
    { title: '前导', icon: <img src={qd} /> },
    { title: '咬合导板', icon: <img src={yhdb} /> },
  ].filter(Boolean);
  return (
    <div className={styles['features-label']}>
      {LABEL_LIST.map(({ title, icon }, index) => {
        return (
          <div className={styles['features-label-item']} key={index}>
            {icon}
            <span>{title}</span>
          </div>
        );
      })}
    </div>
  );
};
const FeaturesMap = ({ cureType, upper, toothDict, type, gdt }: any) => {
  let svgType = '';
  if (cureType === 'C') {
    svgType = upper ? 'mid-baby-upper' : 'mid-baby-lower';
  } else {
    svgType = upper ? 'mid-normal-upper' : 'mid-normal-lower';
  }
  const { GRID_DATA, SVG } = gridData(cureType, upper);
  const ToothLine = ({ side, line2 }: any) => {
    return (
      <div className={[styles['tooth-line']].filter(Boolean).join(' ')}>
        {GRID_DATA?.map(({ number, width }) => {
          const toothInfo = toothDict?.[number]?.[side] || {};
          return (
            <div
              key={number}
              className={`${styles['tooth-item']} ${styles2['tooth-item']}`}
              style={{ width }}
            >
              {number}
              <BoxStep
                line2={line2}
                toothInfo={toothInfo}
                type={type}
                gdt={gdt}
              />
            </div>
          );
        })}
      </div>
    );
  };
  const ToothMask = ({ upper }: any) => {
    const MaskLine = ({ side, line2 }: any) => {
      return (
        <div key={side} className={styles['tooth-mask-line']}>
          {GRID_DATA.map(({ number, height, width, margin }, index) => {
            const style: Record<string, any> = {
              height,
              width,
            };
            if (!line2) {
              style.marginTop = margin;
            } else {
              style.marginBottom = margin;
            }
            const toothInfo = toothDict?.[number]?.[side] || {};
            return (
              <div key={number} className={styles['mask-item']} style={style}>
                <BoxIcon
                  cureType={cureType}
                  upper={upper}
                  side={side}
                  index={index}
                  toothInfo={toothInfo}
                  widthArr={[
                    GRID_DATA?.[index - 1]?.width,
                    width,
                    GRID_DATA?.[index + 1]?.width,
                  ]}
                  margin={margin}
                  type={type}
                  gdt={gdt}
                />
              </div>
            );
          })}
        </div>
      );
    };
    return (
      <div className={styles['tooth-mask']}>
        {upper ? (
          <>
            <MaskLine side="1" />
            <MaskLine side="2" line2 />
          </>
        ) : (
          <>
            <MaskLine side="2" />
            <MaskLine side="1" line2 />
          </>
        )}
      </div>
    );
  };
  return (
    <div className={styles['features-map']}>
      <div className={styles.title}>{upper ? '上颌' : '下颌'}</div>
      <div className={styles[svgType]}>
        {upper ? (
          <>
            <ToothLine side="1" />
            <div className={styles.svg}>
              <ToothMask upper />
              <SVG />
            </div>
            <ToothLine side="2" line2 />
          </>
        ) : (
          <>
            <ToothLine side="2" />
            <div className={styles.svg}>
              <ToothMask />
              <SVG />
            </div>
            <ToothLine side="1" line2 />
          </>
        )}
      </div>
      <div className={styles.side}>
        {upper ? (
          <>
            <div>颊侧</div>
            <div>舌侧</div>
          </>
        ) : (
          <>
            <div>舌侧</div>
            <div>颊侧</div>
          </>
        )}
      </div>
    </div>
  );
};
